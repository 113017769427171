import useJwt from '@/auth/jwt/useJwt'
import BaseServices from '@/store/services/index'
import { handleBlobError } from '@/utils/blobService'
import { downloadInvoiceByType } from '@/utils/blobService/download'
const baseService = new BaseServices()

export default {
  state: {
    manageData: [],
    manageDataCompany: {},
    manageDataCompanyHistory: [],
    billingDataCompanyById: [],
    businessDataNotCreated: [],
    invoiceData: [],
    notesData: [],
    indemnificationsDataCompany: [],
    activationStatusDataCompany:[],
    lastQuery: {},
    collectionDataCompany: [],
    hasBusinessData: false,
    hasShowInvoice: null,
    historyAjust: [],
    carriersBilling: [],
    controlBilling: [],
    billableCompanies: [],
    couriersControl: []
  },
  getters: {
    getManageData: state => state.manageData,
    getManageDataCompany: state => state.manageDataCompany,
    getManageDataCompanyHistory: state => state.manageDataCompanyHistory,
    getBusinessDataNotCreated: state => state.businessDataNotCreated,
    getInvoiceBillingDataCompany: state => state.invoiceData,
    getNotesBillingDataCompany: state => state.notesData,
    getIndemnificationsDataCompany: state => state.indemnificationsDataCompany,
    getBillingDataCompanyById: state => state.billingDataCompanyById,
    getActivationStatusDataCompany: state => state.activationStatusDataCompany,
    getColletionsDataCompany: state => state.collectionDataCompany,
    getHistoryAjust: state => state.historyAjust,
    getHasBusinessData: state => state.hasBusinessData,
    getHasShowInvoice: state => state.hasShowInvoice,
    getCarriersBilling: state => state.carriersBilling,
    getControlBilling: state => state.controlBilling,
    getBillableCompanies: state => state.billableCompanies,
    getCouriersControl: state => state.couriersControl
  },
  mutations: {
    setManageBillingData(state, val) {
      state.manageData = {
        rows: val.rows.map(manageDataCompany => {
          return {
            ...manageDataCompany
          }
        }),
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setManageBillingDataCompany(state, val) {
      state.manageDataCompany = {
        ...val
      }

    },
    setHasBusinessData(state, val) {
      state.hasBusinessData = val
    },
    setHasShowInvoice(state, val) {
      state.hasShowInvoice = val
    },
    setManageDataCompanyHistory(state, val) {
      state.manageDataCompanyHistory = {
        rows: val.data,
        events: val.meta.events,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setBillingDataCompanyById(state, val) {
      state.billingDataCompanyById = val.data
    },
    setBusinessDataNotCreated(state, val) {
      state.businessDataNotCreated = val.rows.map(value => ({ ...value, text: value.name}))
    },
    setInvoiceBillingDataCompany(state, val) {
      state.invoiceData = {
        rows: val.rows,
        sharedTaxID: val.meta.hasSharedTaxId,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setNotesBillingDataCompany(state, val) {
      state.notesData = {
        rows: val.rows,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setIndemnificationsDataCompany(state, val) {
      state.indemnificationsDataCompany = {
        rows: val.rows,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setActivationStatusDataCompany(state, val) {
      state.activationStatusDataCompany = {
        data: val
      }
    },
    setColletionsDataCompany(state, val) {
      state.collectionDataCompany = {
        rows: val.rows,
        seller: val.meta.sellers,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setHistoryAjust(state, val) {
      state.historyAjust = {
        rows: val.rows,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setCarriersBilling(state, val) {
      state.carriersBilling = val.data
    },
    setControlBilling(state, val) {
      state.controlBilling = {
        rows: val.rows,
        pagination: {
          current_page: val.meta.pagination.page,
          total_items: val.meta.total,
          total_pages: val.meta.totalPages
        }
      }
    },
    setBillableCompanies(state, val) {
      state.billableCompanies = val.data
    },
    setCouriersControl(state, val) {
      state.couriersControl = val.data
    }
  },
  actions: {
    changeValueHasBusinessData({ commit }, val) {
      commit('setHasBusinessData', val)
    },
    changeValueHasShowInvoice({ commit }, val) {
      commit('setHasShowInvoice', val)
    },
    fetchManageData({ commit }, { queryParams, params } = {}) {
      baseService.callService('getManageBillingData', queryParams, params)
        .then(response => {
          const currentManageData = {
            rows: response.data,
            pagination: response.pagination
          }
          commit('setManageBillingData', currentManageData)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchManageDataCompany({ commit }, { queryParams, params } = {}) {
      baseService.callService('getManageBillingDataCompany', queryParams, params)
        .then(response => {
          const currentShipper = {
            ...response.data
          }
          commit('setManageBillingDataCompany', currentShipper)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchDeleteManageDataCompany({ commit, state, dispatch }, { queryParams, params } = {}) {
      baseService.callService('deleteManageBillingDataCompany', queryParams, params)
        .then(response => {
          dispatch('fetchManageData', { ...state.lastQuery })
          commit('setMessage', { text: 'msg-exito-eliminar-elemento', type: 'success' })
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-eliminar-elemento', code: err, type: 'alert' })
        })
    },
    downloadDataExcel({ commit }, { params = {}, queryParams = {}, onSuccess = null}) {
      baseService.callService('downloadDataExcel', queryParams, params, { fullResponse: true })
        .then(({ data }) => {
          const objectUrl = URL.createObjectURL(data)
          window.open(objectUrl)
          if (onSuccess) onSuccess()
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    generateBillingControlReport({ commit }, { params = {}, queryParams = {}, onSuccess = null}) {
      baseService.callService('generateBillingControlReport', queryParams, params, { fullResponse: true })
        .then(({ data }) => {
          const objectUrl = URL.createObjectURL(data)
          window.open(objectUrl)
          if (onSuccess) onSuccess()
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchDownloadInvoice({ commit, dispatch }, service) {
      commit('setLoading', { name: service.id || service.name, status: true })
      const { onSuccess, onError, name, queryParams, params, headers, fileName, showToast, responseType } = service
      baseService.callService(name, queryParams, params, { fullResponse: true, fullResponseError: true }, headers, responseType)
        .then((res) => {
          downloadInvoiceByType(res.data, res.headers['content-type'], fileName)
          if (service.onSuccess) onSuccess(res)
        })
        .catch(err => {
          handleBlobError(err, showToast)
          if (service.onError) onError(err)
        }).finally(() => {
          commit('setLoading', { name: service.id || service.name, status: false })
        })
    },
    generateExcelBillingControl({ commit }, { name, params, queryParams = {}, onSuccess = null }) {
      commit('setLoading', { name, status: true })
      baseService
        .callService('generateBillingControlReport', queryParams, params, { fullResponse: true })
        .then(({ data, headers }) => {
          //Name Example : filename=control-de-facturacion-deliveries-costs_20240911-140651_52D4C54B0103.xlsx
          const fileName = `control-de-facturacion-deliveries-costs_${new Date().toISOString().slice(0, 10).replace(/-/g, '')}-${new Date().toISOString().slice(11, 13)}${new Date().toISOString().slice(14, 16)}${new Date().toISOString().slice(17, 19)}.xlsx`
          try {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(data, fileName)
            } else {
              const objectUrl = URL.createObjectURL(data)
              const link = document.createElement('a')
              link.href = objectUrl
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
            }
          } catch (exc) {
            commit('setMessage', { text: 'msg-problema-cargar-datos', code: exc, type: 'alert' })
          }
          if (onSuccess) onSuccess()
        })
        .catch((err) => {
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
        .finally(() => {
          commit('setLoading', { name, status: false })
        })
    }
  }
}
