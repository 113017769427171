import { environment } from '@/environments/environment'
export default [
  { name: 'getMarketcenters', url: `${environment.ottawaApiUrl}/shippers/{shipper_id}/marketcenters`, method: 'get', notCancel: true},
  { name: 'getWarehouses', url: `${environment.ottawaApiUrl}/warehouses/{shipper_id}`, method: 'get'},
  { name: 'getFieldsMarketcenter', url: `${environment.ottawaApiUrl}/marketcenter/{marketcenter_cod}/shippers/{shipper_id}/integration`, method: 'get', notCancel: true},
  { name: 'postConfigMarketcenter', url: `${environment.ottawaApiUrl}/marketcenter/{marketcenter_cod}/shippers/{shipper_id}/integration`, method: 'post', notCancel: true},
  { name: 'getDeliveriesSuccess', url: `${environment.ottawaApiUrl}/all-deliveries/shipper/{shipper_id}/marketcenter/{marketcenter_cod}/limit/{limit}/page/{page}`, method: 'get', notCancel: true},
  { name: 'getDeliveriesFailed', url: `${environment.ottawaApiUrl}/marketcenter/{marketcenter_cod}/orders/{shipper_id}/failed`, method: 'get'},
  { name: 'getMarketcenterByShipperId', url: `${environment.ottawaApiUrl}/list-marketcenters-by-shipper-id/{shipper_id}`, method: 'get'},
  { name: 'getProductsMarketcenter', url: `${environment.ottawaApiUrl}/marketcenter/products/{shipper_id}/ecommerce/limit/{limit}/offset/{offset}/mode/{mode}`, method: 'post'},
  { name: 'postUploadProducts', url: `${environment.ottawaApiUrl}/update-massive-change-shipping-mode-meli/shipper/{shipper_id}`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'postDimensions', url: `${environment.ottawaApiUrl}/change-shipping-mode/shipper/{shipper_id}`, method: 'put'},
  { name: 'getSummaries', url: `${environment.ottawaApiUrl}/summaries`, method: 'get'},
  { name: 'postPrintSummaries', url: `${environment.ottawaApiUrl}/summaries/{shipper_id}`, method: 'post'},
  { name: 'putCloseSummaries', url: `${environment.ottawaApiUrl}/summaries-close/{shipper_id}'`, method: 'put'},
  { name: 'getPrinterDeliveries', url: `${environment.ottawaApiUrl}/label-deliveries/shipper/{shipper_id}/limit/{limit}/page/{page}`, method: 'get'},
  { name: 'putRemoveDelivery', url: `${environment.ottawaApiUrl}/remove-delivery-summaries`, method: 'put'},
  { name: 'deleteIntegration', url: `${environment.ottawaApiUrl}/shipper/{shipper_id}/marketcenter/{marketcenter_cod}/remove`, method: 'delete', notCancel: true},
  { name: 'deleteIntegrationMkc', url: `${environment.ottawaApiUrl}/shipper/{shipper_id}/marketcenter/{marketcenter_cod}/remove`, method: 'delete', notCancel: true},
  { name: 'deleteDeliveryMkc', url: `${environment.ottawaApiUrl}/delete-deliveries/shipper/{shipper_id}`, method: 'delete'},
  { name: 'getListPrinted', url: `${environment.ottawaApiUrl}/printed-deliveries/shipper/{shipper_id}/limit/{limit}/page/{page}`, method: 'get'},
  { name: 'exportDeliveriesModulePrinted', url: `${environment.estambulApiUrl}/deliveries/export`, method: 'post'},
  { name: 'updateStatusIntegration', url: `${environment.ottawaApiUrl}/shippers/{shipper_id}/{marketcenter_cod}/change-webhook-state`, method: 'patch', notCancel: true}
]